import { Link } from 'gatsby';
import React from 'react';

import stories from '../images/auto-stories.svg';
import blog from '../images/blog.svg';
import call from '../images/call-24-px.png';
import chat from '../images/chat.png';
import team from '../images/groups.svg';
import helpOutline from '../images/help-outline.svg';
import email from '../images/ic-email-24-px.png';

const AboutHeader = () => {
  // return (
  //   <div className="bgFFF aboutPage">
  //     <div className="flexRow justify-content-between makeColumn">
  //       <div className="flexColumn">
  //         <img src={helpOutline} alt="help" />

  //         <div className="title">Who are we</div>

  //         <p className="para">
  //               Bubble offers flexible financial products like Home and Life insurance together to ensure your dependants are secure.
  //         </p>

  //         <Link className="learnMorePadding" to="/about/">
  //           <div className="learnMore">Learn More<div className="orangeTriangle"></div></div>
  //         </Link>
  //       </div>

  //       <div className="flexColumn">
  //         <img src={stories} alt="story" />

  //         <div className="title">Our Story</div>

  //         <p className="para">
  //             Bubble's mission is to be the most convenient way for people to protect their homes &#38; family.
  //         </p>

  //         <Link className="learnMorePadding" to="/our-story/">
  //           <div className="learnMore">Learn More<div className="orangeTriangle"></div></div>
  //         </Link>
  //       </div>

  //       <div className="flexColumn">
  //         <img src={team} alt="team" style={{ width: '30px', height: '30px', marginBottom: '2px' }} />

  //         <div className="title">Our Team</div>

  //         <p className="para">
  //             Bubble's founding team has decades of experience in technology, data science, AI, economics, and innovation.
  //         </p>

  //         <Link className="learnMorePadding" to="/our-team/">
  //           <div className="learnMore">Learn More<div className="orangeTriangle"></div></div>
  //         </Link>
  //       </div>

  //       <div className="flexColumn">
  //         <img src={blog} alt="team" style={{ width: '30px', height: '30px', marginBottom: '2px' }}/>

  //         <div className="title">Blogs</div>

  //         <p className="para">Read our blog where industry experts and our team talk about what's new, old, and everything else in the world of life and home insurance.</p>

  //         <a className="learnMorePadding" rel="noreferrer noopener"
  //           target="_blank"  href="https://www.getmybubble.com/blog" >
  //           <div className="learnMore">Learn More<div className="orangeTriangle"></div></div>
  //         </a>
  //       </div>

  //       <div className="flexColumn touch">
  //         <div className="title">Get in touch with us</div>

  //         <div className="flexRow">
  //           <img src={call} alt="call" />

  //           <div className="makeColumn">
  //             <div className="learnMore fwNormal MobileLftPadding">(833) 900-2822</div>

  //             <p className="para">(Monday-Sunday, 8AM-8PM ET)</p>
  //           </div>
  //         </div>

  //         <div className="flexRow">
  //           <img src={email} alt="email" />

  //           <div className="makeColumn">
  //             <a className="MobileLftMargin" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
  //               <div className="learnMore fwNormal">hello@getmybubble.com</div>
  //             </a>

  //             <p className="para" style={{ opacity: 0 }}>(24/7, at your service)</p>
  //           </div>
  //         </div>

  //         <div className="flexRow d-none">
  //           <img src={chat} alt="chat" style={{ width: '15px', height: '14px' }} />

  //           <div className="makeColumn">
  //             <div className="learnMore fwNormal">Live Chat</div>

  //             <p className="para">(Chat with an Agent now)</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div> );

  return (
    <div className="dropdown-menutest">
      <ul className="droplist">
        <li>
          <Link to="/about/">
            Who are we
          </Link>
        </li>

        <li>
          <Link to="/our-story/">
            Our Story
          </Link>
        </li>

        <li>
          <Link to="/our-team/">
            Our Team
          </Link>
        </li>

        <li>
          <Link rel="noreferrer noopener" target="_blank"  to="https://www.getmybubble.com/blog" className=''>
            Blog
          </Link>
        </li>

        <li>
          <Link to="/testimonial/" className=''>
            Testimonial
          </Link>
        </li>

        {/* <li>
          <a className=''>Get in touch with us</a>

          <p><img src={call} alt="call" /><span>(833) 900-2822</span>

            <span className="para">(Monday-Sunday, 8AM-8PM ET)</span></p>

          <p>
            <a className="MobileLftMargin" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
              <div className="learnMore fwNormal"><img src={email} alt="email" /> hello@getmybubble.com</div>
            </a>
          </p>
        </li> */}
      </ul>
    </div>
  );
};
export default AboutHeader;
