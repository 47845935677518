import '../styles/loader.css';

import React from 'react';

function Loader() {
  return (
    <div id="pause" className="d-flex align-items-center justify-content-center">
      <div id="spinner"></div>
    </div>
  );
}

export default Loader;
